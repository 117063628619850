import React, { useEffect, useRef, useState } from "react";
import { graphql } from "gatsby";
import { withPreview } from "gatsby-source-prismic";
// import { Controller, Scene } from "react-scrollmagic";
// import { Tween } from "react-gsap";
import SEO from "../components/seo";
// import Img from "gatsby-image";
import BackgroundImage from "gatsby-background-image";
// import styled from "styled-components";
import ScrollerYToX from "../components/ui/ScrollerYToX";

export const pageQuery = graphql`
  query GalleryQuery {
    prismicDaily {
      uid
      data {
        meta_title {
          text
        }
        meta_description {
          text
        }
        meta_image {
          url
        }
        images {
          image {
            ...sharp
          }
        }
      }
    }
  }
`;

const MagicScrollerImages = ({ input }) => (
  <ScrollerYToX>
    {input.map(({ image }, key) => (
      <div className='item' key={key}>
        <BackgroundImage
          Tag='figure'
          className={"h-screen"}
          fluid={image.fluid}
          style={{
            width: "50vw",
            backgroundAttachment: "fixed",
            backgroundSize: "cover",
            backgroundPosition: "center center",
          }}
        />
      </div>
    ))}
  </ScrollerYToX>
);

const Gallery = ({ data, pageContext }) => {
  const { template } = pageContext;
  const { meta_title, meta_description, meta_image, images } =
    data.prismicDaily.data;

  const half = Math.round(images.length / 2);
  const arrImgsLeft = images.slice(0, half);
  const arrImgsRight = images.slice(half, images.length);

  // console.log(totalWidth);

  return (
    <div className='page-template page-gallery'>
      <SEO
        pageTitle={meta_title.text}
        pageDescription={meta_description.text}
        pageImage={meta_image.url}
        template={`${template} overflow-hidden`}
        documentClass='no-scroll'
        page={false}
      />
      <div className='flex'>
        <div className='images w-50'>
          <MagicScrollerImages input={arrImgsLeft} />
        </div>
        <div className='images w-50'>
          <MagicScrollerImages input={arrImgsRight} />
        </div>
      </div>
    </div>
  );
};

export default withPreview(Gallery);
