import React, { useEffect, useRef } from "react";
import styled from "styled-components";

const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-wrap: nowrap;
  overflow: hidden;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }
  & > div {
    flex: 0 0 auto;
  }
`;

const ScrollerYToX = ({ children }) => {
  const scrollerRef = useRef();
  useEffect(() => {
    // console.log(scrollerRef.current);
    scrollerRef.current.addEventListener("wheel", _onWheel);
    return () => {
      if (scrollerRef && scrollerRef.current)
        scrollerRef.current.removeEventListener("wheel", _onWheel);
    };
  }, []);

  const _onWheel = (e) => {
    console.log(e);
    if (e.deltaY > 0) scrollerRef.current.scrollLeft += 20;
    else scrollerRef.current.scrollLeft -= 20;
  };

  return (
    <Container className='' ref={scrollerRef}>
      {children}
    </Container>
  );
};

export default ScrollerYToX;
